// react libraries import
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

//custom libraries import


const MessageFromPrinciple = () => {
    
    return(
        <main  className="px-8 md:px-24 pt-2 md:pt-4 pb-16 text-base">
            <h3 className="text-2xl uppercase text-primary text-center font-bold my-8">Message from Principle</h3>
            <p  className="my-3">
                Dear Parents, students and well wishers of the <b>Shree Mahendra Secondary School</b>, my wishes full of prayers to all of you. First of all I would like to express my deep sentiments of gratitude to everyone for your constant support, love and concern towards the school which enables and encourages us to strive hard to carry our mission of spreading value based knowledge to one and all.
                <StaticImage
                    src="../images/message/principal.jpg"
                    width={360}
                    quality={100}
                    className="mb-4 md:mb-0 mt-2 ml-4 w-200 float-right"
                    formats={["auto", "webp", "avif"]}
                    alt="School Logo"
                />
            </p>
            <p className="my-12 md:my-8">
                “Education is a shared commitment between dedicated teachers, motivated students and enthusiastic parents with high expectations.” Education is an extremely important instrument of human development. The main aim of education is to provide excellence in all aspects of life of the children. Our main objectives are to develop the children physically, socially, morally, intellectually and spiritually through constructive education. So that children can face the challenges of their lives and fulfill their duties and responsibilities well. Today, the role of a school is not only to pursue academic excellence but also to motivate and empower the students to be lifelong learners, critical thinkers and productive members of an ever-changing global society. Our school is striving hard to make the best positive effort to inculcate strong values combining with academics and extracurricular activities in the children.
            </p>
            <p className="my-8">
                Since the inception of our school establishment, there have been a lot of changes that have taken place in the school as well as the different aspects of the varying dimensions of society and the world, politically, culturally, economically and so on. Since, we are all inter-related, we deeply affect one another. Hence, to smoothly operate the school and impart the quality we are aiming to provide is challenging, however, with the dedicated teachers and enthusiastic parents, we have been able to deliver up to the mark of high expectations and thus, inspire students in such a way that they are self-motivated and driven. Education is not merely teaching a mind to memorize the facts but rather is the process of creating a mind that is able to think. What we aspire to give to the world are global citizens, independent thinkers, innovators and creators for which we create an environment for all learners to nurture and enrich to its fullest potential and the highest dimensions of consciousness. Our great cultures and traditions have forever inspired us to live a life of integrity, responsibility and empathy to reach to the peak of consciousness from where the supreme consciousness flowers.
            </p>
            <p className="text-left font-medium float-right text-danger">
                Principle<br></br>
                Jagannath Adhikari
            </p>
        
        </main>
    );
}


export default MessageFromPrinciple;